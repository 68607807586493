import React, { useState } from 'react';
import axios from 'axios';
import './ImageGenerator.css';

const ImageGenerator = () => {
  const [prompt, setPrompt] = useState('');
  const [imageData, setImageData] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    setPrompt(e.target.value);
  };

  const generateImage = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://text-to-image-worker.ishavverma.workers.dev/', { prompt }, { responseType: 'arraybuffer' });
      const blob = new Blob([response.data], { type: 'image/png' });
      setImageData(URL.createObjectURL(blob));
      setError(null);
    } catch (error) {
      console.error('Error generating image:', error);
      setError('Error generating image. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="image-generator-container">
      <h1 className="header">DreamIT Image Generator</h1>
      <div className="image-container">
        {imageData && (
          <img src={imageData} alt="Generated" className="generated-image" />
        )}
      </div>
      <div className="input-container">
        <input
          type="text"
          placeholder="Enter prompt..."
          value={prompt}
          onChange={handleInputChange}
          className="prompt-input"
        />
        <button onClick={generateImage} disabled={loading} className="generate-button">
          {loading ? 'Generating...' : 'Generate Image'}
        </button>
        {error && <div className="error">{error}</div>}
      </div>
    </div>
  );
};

export default ImageGenerator;
